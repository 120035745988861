import React, { Component } from 'react'
import $ from 'jquery'
import CampaignsDropDown from '../../Bricks/CampaignsDropDown'
import ScriptsDropDown from '../../Bricks/ScriptsDropDown'
import TimeKeeper from 'react-timekeeper'
import TagsInput from 'react-tagsinput'
import { TimePicker, Checkbox, Tooltip } from 'antd'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.
import moment from 'moment'
import HeadTitle from '../../HeadTitle'

import {
  convertJustTimeToUtc,
  convertJustTimeFromUtcToCurrentTimeZone,
} from '../../../helpers'

const { RangePicker } = TimePicker
class ListEdit extends Component {
  state = {
    list_id: '',
    list_name: '',
    campaign_id: '',
    active: 'Y',
    reset_called_leads: 'N',
    reset_time: '',
    agent_script_override: '',
    list_lastcalldate: '',
    time: '12:00pm', //window.hourwithAMPM(new Date()),
    timetags: [],
    timetagsUTC: [],
    local_call_times: '',
    time_zone_setting: 'MANUAL',
    use_campaign_call_time: false,
    once_displayed_call_time_modal_on_call_time_focus: false,
  }
  componentDidMount() {
    //const time = window.hourwithAMPM(new Date());
    //if (time) this.setState({ time: window.hourwithAMPM(new Date()) });
    window.resizeHandle()
    this.list_id = this.props.match.params.list_id
    /*/load its specific js file ;)
    const script = document.createElement("script");
    script.src = "jsdolphin/listedit.js";
    script.async = true;
    document.body.appendChild(script);
    ////////////////////////////////////*/
    //window.load_select2(".bs-select");
    //window.apply_timepicker_no_seconds();
    //window.load_list_add_js();

    //fetch and fill with info
    fetch(this.props.baseUrl + '/index.php/Lists/Modify/' + this.list_id, {
      method: 'GET',
      credentials: 'include',
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.error(resp)

        //manage list reset taime tags//
        var ttagsUTC =
          resp.msg.reset_time.trim() !== ''
            ? resp.msg.reset_time.split(',')
            : []

        let ttags = []
        ttagsUTC.forEach((tag) => {
          ttags.push(convertJustTimeFromUtcToCurrentTimeZone(tag))
        })
        //list reset time tags above
        this.setState({
          list_id: resp.msg.list_id,
          list_name: resp.msg.list_name,
          campaign_id: resp.msg.campaign_id,
          active: resp.msg.active,
          reset_called_leads: 'No',
          reset_time: resp.msg.reset_time,
          timetagsUTC: ttagsUTC,
          timetags: ttags,
          agent_script_override: resp.msg.agent_script_override,
          list_lastcalldate: resp.msg.list_lastcalldate,
          local_call_times: resp.msg.local_call_time,
          use_campaign_call_time:
            resp.msg.local_call_time == 'campaign' ? true : false,
          time_zone_setting: resp.msg.time_zone_setting,
        })
        window.$('.bs-select').trigger('change')
      })
    ///////////////////////////////
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp
    //console.error(this.props.match.params);
    //alert(show_alert);
    if (show_alert !== undefined) {
      window.displayAlert(
        '#alert_container',
        'List Successfully Added. ',
        'success'
      )
    }
  }
  submitEditList = (form) => {
    console.warn($('.listEditform').serialize())
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Lists/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('.listEditform').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleclearList = (form) => {
    form.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Lists/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#clearList').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
        window.$('#mdl_clear_list').modal('hide')
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  showTimeModal = (e) => {
    if (this.state.timetags.length >= 2) {
      window.bootbox.alert('You can add two reset times at maximum.')
    } else {
      window.$('#modal_cf_tp').modal('show')
    }
  }
  getmomentarr = () => {
    try {
      let vv = this.state.local_call_times
      if (vv.indexOf('-') <= 0) {
        return [null, null]
      }
      let a = vv.split('-')
      let ar = [moment(a[0], 'hh:mm:ss a'), moment(a[1], 'hh:mm:ss a')]
      //console.log(ar[0]);
      //console.log(ar[1]);
      return ar
    } catch (e) {
      return [null, null]
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <div className="page-content-wrapper">
            <HeadTitle title="Lists" subtitle="edit" />
            <div className="page-content">
              <div className="container">
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                      <span id="alert_container"></span>
                      <div className="portlet light portlet-fit ">
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <form
                                className="listEditform"
                                onSubmit={this.submitEditList}
                              >
                                <input
                                  type="hidden"
                                  name="action"
                                  value="update_lists_settings"
                                />
                                <input
                                  type="hidden"
                                  name="list_id"
                                  value={this.state.list_id}
                                />
                                <table className="table table-striped table-bordered table-advance table-hover">
                                  <tbody>
                                    <tr>
                                      <td>List ID</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_id"
                                          value={this.state.list_id}
                                          readOnly
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>List Name</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="list_name"
                                          value={this.state.list_name}
                                          onChange={(ev) => {
                                            this.setState({
                                              list_name: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Campaign</td>
                                      <td>
                                        <CampaignsDropDown
                                          baseUrl={this.props.baseUrl}
                                          name="list_campaign_id"
                                          value={this.state.campaign_id}
                                          class="bs-select"
                                          onChange={(ev) => {
                                            this.setState({
                                              campaign_id: ev.target.value,
                                            })
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Active</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="list_active"
                                          id="list_active"
                                          value={this.state.active}
                                          onChange={(ev) => {
                                            this.setState({
                                              active: ev.target.value,
                                            })
                                          }}
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        Reset Lead-Called-Status for this list
                                      </td>
                                      <td>
                                        <select
                                          className=" form-control"
                                          name="list_reset_lead_called_status"
                                          value={this.state.reset_called_leads}
                                          onChange={(ev) => {
                                            this.setState({
                                              reset_called_leads:
                                                ev.target.value,
                                            })
                                          }}
                                        >
                                          <option value="N">No</option>
                                          <option value="Y">Yes</option>
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Reset Times</td>
                                      <td>
                                        <div className="input-group">
                                          <span className="input-group-btn">
                                            <button
                                              className="btn red"
                                              type="button"
                                              id="clear_reset_times"
                                              onClick={(e) => {
                                                this.setState({
                                                  timetags: [],
                                                  timetagsUTC: [],
                                                })
                                              }}
                                            >
                                              <i className="fa fa-times"></i>
                                            </button>
                                          </span>
                                          {/*<input
                                            className="form-control"
                                            readOnly
                                            id="list_reset_times"
                                            name="list_reset_times"
                                            type="text"
                                            value={this.state.reset_time}
                                            onChange={(ev) => {
                                              this.setState({
                                                reset_time: ev.target.value,
                                              });
                                            }}
                                          />*/}
                                          <input
                                            type="hidden"
                                            name="list_reset_times"
                                            value={this.state.timetagsUTC.join(
                                              ','
                                            )}
                                          />
                                          <TagsInput
                                            disabled
                                            maxTags={2}
                                            value={this.state.timetags}
                                            onChange={(e) => {
                                              this.setState({ timetags: e })
                                            }}
                                            inputProps={{ placeholder: '' }}
                                          />
                                          <span className="input-group-btn">
                                            <button
                                              className="btn green-jungle"
                                              id="btn_add_reset_time"
                                              type="button"
                                              onClick={this.showTimeModal}
                                            >
                                              <i className="fa fa-plus"></i>
                                            </button>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>Script Override</td>
                                      <td>
                                        <ScriptsDropDown
                                          name="list_script_override"
                                          id="list_script_LtA"
                                          class="bs-select"
                                          baseUrl={this.props.baseUrl}
                                          value={
                                            this.state.agent_script_override
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              agent_script_override:
                                                e.target.value,
                                            })
                                          }}
                                        />
                                        {/*<select
                                          className="bs-select form-control"
                                          name="list_script_override"
                                          value={
                                            this.state.agent_script_override
                                          }
                                          onChange={(ev) => {
                                            this.setState({
                                              agent_script_override:
                                                ev.target.value,
                                            });
                                          }}
                                        >
                                          <option value="Y">Yes</option>
                                          <option value="N">No</option>
                                        </select>*/}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>List Last Call Date</td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          readonly
                                          name="list_last_call_date"
                                          value={this.state.list_lastcalldate}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          paddingTop: '20px',
                                        }}
                                      >
                                        Call Time&nbsp;
                                        <i
                                          onClick={(e) => {
                                            if (
                                              !window
                                                .$('#static_call_time_modal')
                                                .is(':visible')
                                            ) {
                                              window
                                                .$('#static_call_time_modal')
                                                .modal('show')
                                            }
                                          }}
                                          style={{
                                            fontSize: '15px',
                                            cursor: 'pointer',
                                          }}
                                          title="Call Time allows you to set an allowable time for agents to call the lists in this campaign based on the system Time Zone setting under Configurations.  Note: Some people move to a different time zone, but keep their phone number from their previous time zone; therefore, you could potentially call someone at a time of day that is not allowed."
                                          class="material-icons"
                                        >
                                          info
                                        </i>
                                      </td>
                                      <td>
                                        {!this.state.use_campaign_call_time ? (
                                          <React.Fragment>
                                            <input
                                              type="hidden"
                                              name="local_call_time"
                                              value={
                                                this.state.local_call_times
                                              }
                                            />
                                            <RangePicker
                                              onOpenChange={(a) => {
                                                if (a) {
                                                  if (
                                                    !this.state
                                                      .once_displayed_call_time_modal_on_call_time_focus
                                                  ) {
                                                    if (
                                                      !window
                                                        .$(
                                                          '#static_call_time_modal'
                                                        )
                                                        .is(':visible')
                                                    ) {
                                                      window
                                                        .$(
                                                          '#static_call_time_modal'
                                                        )
                                                        .modal('show')
                                                    }
                                                    this.setState({
                                                      once_displayed_call_time_modal_on_call_time_focus: true,
                                                    })
                                                  }
                                                }
                                              }}
                                              use12Hours={true}
                                              onChange={(val, time) => {
                                                this.setState({
                                                  local_call_times:
                                                    time.join('-'),
                                                })
                                              }}
                                              className="form-control"
                                              format={'hh:mm:ss a'}
                                              value={this.getmomentarr()}
                                            />
                                          </React.Fragment>
                                        ) : (
                                          ''
                                        )}
                                        <input
                                          type="hidden"
                                          name="use_campaign_call_time"
                                          value={
                                            this.state.use_campaign_call_time
                                          }
                                        />

                                        <Checkbox
                                          onChange={(val) => {
                                            //alert(val.target.checked);
                                            this.setState({
                                              use_campaign_call_time:
                                                val.target.checked,
                                            })
                                          }}
                                          checked={
                                            this.state.use_campaign_call_time
                                          }
                                        >
                                          Use Campaign Call Time
                                        </Checkbox>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Time Zone Setting</td>
                                      <td>
                                        <select
                                          className="bs-select form-control"
                                          name="time_zone_setting"
                                          id="time_zone_setting"
                                          value={this.state.time_zone_setting}
                                          onChange={(ev) => {
                                            this.setState({
                                              time_zone_setting:
                                                ev.target.value,
                                            })
                                          }}
                                        >
                                          <option value="MANUAL">
                                            Specify Per Lead
                                          </option>
                                          <option value="COUNTRY_AND_AREA_CODE">
                                            Area Code
                                          </option>
                                          {/*<option value="POSTAL_CODE">
                                            Postal Code
                                        </option>*/}
                                        </select>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="text-center" colSpan="2">
                                        <button
                                          type="submit"
                                          className="btn green-jungle"
                                        >
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          id="list_clear"
                                          data-toggle="modal"
                                          href="#mdl_clear_list"
                                          className="btn btn-warning"
                                        >
                                          Clear List
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<!---------------Clockfaced timepicker in modal--------->*/}
        <div id="modal_cf_tp" className="modal fade" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add Reset Time</h4>
              </div>
              <div className="modal-body">
                <form action="#" className="form-horizontal">
                  <div className="form-group">
                    <div className="col-md-6 col-md-offset-3">
                      <TimeKeeper
                        time={this.state.time ?? null}
                        onChange={(e) => this.setState({ time: e.formatted12 })}
                        switchToMinuteOnHourSelect
                      />
                      {/*<div className="input-group">
                        <input
                          type="text"
                          id="curTime"
                          className="form-control timepicker timepicker-no-seconds"
                        />
                        <span className="input-group-btn">
                          <button className="btn default" type="button">
                            <i className="fa fa-clock-o"></i>
                          </button>
                        </span>
                      </div>*/}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  className="btn default"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  Close
                </button>
                <button
                  className="btn green-jungle"
                  id="add_to_reset_times"
                  data-dismiss="modal"
                  onClick={(e) => {
                    let tt = this.state.timetags
                    if (tt.includes(this.state.time) === false)
                      tt.push(this.state.time)
                    this.setState({ timetags: tt }, () => {
                      let ttu = []
                      this.state.timetags.forEach((tag) => {
                        ttu.push(convertJustTimeToUtc(tag))
                      })
                      this.setState({ timetagsUTC: ttu })
                    })
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*<!---------------------------------------------------------------------------------><!--- Clear List MOdal --->*/}
        <div
          className="modal fade"
          id="mdl_clear_list"
          tabIndex="-1"
          role="mdl_clear_list"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Confirm!</h4>
              </div>
              <div className="modal-body"> Are you sure? </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn dark btn-outline"
                  data-dismiss="modal"
                >
                  No
                </button>
                <form
                  style={{ display: 'inline-block', marginLeft: '2px' }}
                  id="clearList"
                  onSubmit={this.handleclearList}
                >
                  <input type="hidden" name="action" value="clear_list" />
                  <input
                    type="hidden"
                    name="list_id"
                    value={this.state.list_id}
                  />
                  <button type="submit" className="btn green-jungle">
                    Yes
                  </button>
                </form>
              </div>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!-------------------------------------------------------------------------------------->*/}
        {/***Modal for agreement sining */}
        <div
          id="static_call_time_modal"
          className="modal fade"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h4 className="modal-title">
                  Calling Software and Phone Services Agreement
                </h4>
              </div> */}
              <div className="modal-body">
                <p>
                  Call Time restricts the time that agents are allowed to dial
                  on this campaign based on the system Time Zone setting under
                  Configurations. Note: Keep in mind, some customers may move to
                  different parts of the country. For example, while a
                  customer's area code may be east coast, they may actually be
                  living on the west coast. Therefore you could potentially call
                  someone at a time of day that's not legally allowed.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn green"
                  onClick={(e) => {
                    if (window.$('#static_call_time_modal').is(':visible')) {
                      window.$('#static_call_time_modal').modal('hide')
                    }
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/******Modal for agreement end */}
      </React.Fragment>
    )
  }
}

export default ListEdit
