import React, { Component } from 'react'
import $ from 'jquery'
import { Popconfirm, Collapse, TimePicker, Checkbox, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import HeadTitle from '../../HeadTitle'

import { Table } from 'antd'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'
import AreaCIDCampaignWidget from '../../Bricks/AreaCIDCampaignWidget'
import DynamicCIDCampaignWidget from '../../Bricks/DynamicCIDCampaignWidget'
const { RangePicker } = TimePicker

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))
class CampaignEdit extends Component {
  hotkey_options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  state = {
    useMasterDialDefaults: false,
    campaign_id: '',
    campaign_name: '',
    active: '',
    campaign_cid: '',
    sms_cid: '',
    manual_preview_dial: '',
    campaign_script: '',
    campaign_recording: '',
    dial_timeout: 0,
    agent_cid_override: 'Y',
    campaign_dial_order: 'standard',
    auto_alt_dial: 'N',
    three_way_call_cid: 'CAMPAIGN', //CAMPAIGN = Dolphin, CUSTOMER=Leads CID
    standard_dispo: [],
    dial_dispo: [],
    auto_alt_dial_statuses: [],
    system_dispos: [],
    campaign_dispos: [],
    campaign_lists: [],
    all_statuses: [],
    standard_dispo: [],
    enable_recording: 'Yes',
    scripts_list: [],
    local_call_times: '',
    once_displayed_call_time_modal_on_call_time_focus: false,
  }

  users_campaign_values = null
  masterdialdefault = null
  dt_presets = null
  dt_hotkeys = null
  dt_leadrecycle = null
  //dy_dynamic_cids = null;
  columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'List ID',
      dataIndex: 'list_id',
      className: 'drag-visible',
    },
    {
      title: 'List Name',
      dataIndex: 'list_name',
      className: 'drag-visible',
    },
    // {
    //   title: 'Leads (Dialables)',
    //   dataIndex: 'leads',
    //   render: (text, row) => {
    //     return text // + ' (' + row.dialable_leads + ')'
    //   },
    // },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (text, row) => {
        return (
          <Checkbox
            onChange={(ev) => {
              this.toggleListActive(ev, row.list_id)
              let tstate = this.state

              for (var i = 0; i < tstate.campaign_lists.length; i++) {
                if (tstate.campaign_lists[i].list_id === row.list_id) {
                  if (ev.target.checked) tstate.campaign_lists[i].active = 'Y'
                  else tstate.campaign_lists[i].active = 'N'
                  break
                }
              }

              this.setState(tstate)
            }}
            checked={row.active == 'Y'}
          ></Checkbox>
        )
      },
    },
  ]
  constructor(props) {
    super(props)
  }
  componentWillUnmount() {
    window.removeJS('jsdolphin/campaignedit.js')
  }
  componentDidMount() {
    var cid = this.props.match.params.campaign_id
    this.setState({ campaign_id: cid })
    //window.load_select2(".bs-select");
    window.resizeHandle()
    //window.App.initUniform();

    this.dt_presets = window.dttable(
      'dt_presets',
      this.props.baseUrl + '/index.php/Ajax/list_campaigns_presets/' + cid,
      ''
    )

    this.dt_hotkeys = window.dttable(
      'dt_hotkeys',
      this.props.baseUrl + '/index.php/Ajax/list_hotkeys/' + cid,
      ''
    )
    this.dt_leadrecycle = window.dttable(
      'dt_leadrecycle',
      this.props.baseUrl + '/index.php/Ajax/list_leadRecycle/' + cid,
      ''
    )
    //load its specific js file ;)
    const script = document.createElement('script')
    script.src = 'jsdolphin/campaignedit.js'
    script.async = true
    document.body.appendChild(script)
    this.initCampaign()

    var Aurl = this.props.baseUrl
    var dtable_presets = this.dt_presets
    var dtable_leadrecycle = this.dt_leadrecycle
    var dtable_hotkeys = this.dt_hotkeys
    var blockUI = this.props.blockUI
    /****Below is delete control for lead recycle */

    $('#dt_leadrecycle').on('click', '.btn-danger', function () {
      $(this).closest('tr').remove()
      var childKey = $(this).attr('delete_leadrecycleid')
      var campaign_id = $(this).attr('campaign_leadrecycle')
      blockUI(true)
      fetch(
        Aurl +
          '/index.php/Campaigns/LeadRecycle/delete/' +
          childKey +
          '/' +
          campaign_id,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            window.displayAlert('#alert_container', data.msg, 'success')
          } else {
            window.displayAlert('#alert_container', data.msg, 'danger')
          }
          dtable_leadrecycle.api().ajax.reaload()
          blockUI(false)
        })
        .catch((e) => {
          blockUI(false)
        })
    })
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    /****Below is delete control for Hotkeys */

    $('#dt_hotkeys').on('click', '.btn-danger', function () {
      $(this).closest('tr').remove()
      var childKey = $(this).attr('delete_id')
      var campaign_id = $(this).attr('campaign_id')
      blockUI(true)
      fetch(
        Aurl +
          '/index.php/Campaigns/Hotkeys/delete/' +
          childKey +
          '/' +
          campaign_id,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            window.displayAlert('#alert_container', data.msg, 'success')
          } else {
            window.displayAlert('#alert_container', data.msg, 'danger')
          }
          dtable_hotkeys.api().ajax.reaload()
          blockUI(false)
        })
        .catch((e) => {
          blockUI(false)
        })
    })
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    /****Below is delete control for Hotkeys */

    $('#dt_presets').on('click', '.btn-danger', function () {
      /*$(this)
		.closest("tr")
		.remove();*/
      var childKey = $(this).attr('delete_id')
      var campaign_id = $(this).attr('campaign_id')
      blockUI(true)
      fetch(
        Aurl +
          '/index.php/Campaigns/Preset/delete/' +
          childKey +
          '/' +
          campaign_id,
        {
          method: 'GET',
          credentials: 'include',
        }
      )
        .then((blob) => {
          return blob.json()
        })
        .then((data) => {
          console.log(data)
          if (data.status) {
            window.displayAlert('#alert_container', data.msg, 'success')
          } else {
            window.displayAlert('#alert_container', data.msg, 'danger')
          }
          dtable_presets.api().ajax.reload()
          blockUI(false)
        })
        .catch((e) => {
          blockUI(false)
        })
    })
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    ///check if user edit has sent back we mgiht need to show alert :)
    const show_alert = this.props.match.params.alert_disp
    //console.error(this.props.match.params);
    //alert(show_alert);
    if (show_alert !== undefined) {
      window.displayAlert(
        '#alert_container',
        'Campaign Successfully Edited. ',
        'success'
      )
    }
  }

  initCampaign = () => {
    var cid = this.props.match.params.campaign_id
    ////////////////////////////////////
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/Modify/' + cid, {
      method: 'GET',
      credentials: 'include',
    })
      .then((blob) => blob.json())
      .then((data) => {
        console.log(data)
        data = data.msg
        var campaign = data.campaign
        this.setState(
          {
            campaign_name: campaign.campaign_name,
            active: campaign.active,
            campaign_cid_type: campaign.campaign_cid_type,
            campaign_cid: campaign.campaign_cid,
            sms_cid: campaign.sms_cid,
            campaign_script: campaign.campaign_script,
            campaign_recording: campaign.campaign_recording,
            dial_timeout: campaign.dial_timeout,
            agent_cid_override: campaign.agent_cid_override,
            campaign_dial_order: campaign.campaign_dial_order,
            manual_preview_dial: campaign.manual_preview_dial,
            auto_alt_dial: campaign.auto_alt_dial,
            dial_dispo: data.dial_dispo,
            auto_alt_dial_statuses: data.auto_alt_dial_statuses,
            system_dispos: data.system_dispos,
            campaign_dispos: data.campaign_dispos,
            standard_dispo: data.std_dial_dispo,
            campaign_lists: data.campaigns_lists,
            all_statuses: data.all_statuses,
            enable_recording: data.system_settings.enable_recording,
            scripts_list: data.scripts,
            local_call_times: campaign.local_call_time,
            three_way_call_cid: campaign.three_way_call_cid,
          },
          function () {
            //this.doDynamicCIDWidget();
          }
        )
        if (campaign.follow_master == 'Y') {
          /*if (!window.$("#use_master_dial").is(":checked"))
            window.$("#use_master_dial").click();*/
          this.setState({ useMasterDialDefaults: true }, () => {
            this.handleMasterDialDefaults(this.state.useMasterDialDefaults)
          })
        }
        //console.error(this.state);
        //window.App.initUniform();
        //window.$(".bs-select").trigger("change");
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handlesubmitEditCampaign = (form) => {
    form.preventDefault()
    if (Number.parseInt(this.state.dial_timeout) < 30) {
      window.displayAlert(
        '#alert_container',
        'Dial Timeout should be atleast 30',
        'danger'
      )
      return
    }

    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('.CampaignEditForm').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          this.initCampaign()
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        this.props.blockUI(false)
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddCampaignDispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/Add_campaignDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_add_campaign_dispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_camp_dispo').modal('hide')
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleAddDialableDispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/Add_dialDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_adddialdispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_sys_dispo').modal('hide')
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddtransferpreset = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addtransferpreset').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_trxPreset').modal('hide')
        this.dt_presets.api().ajax.reload()
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddautoaltdialdispo = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/DispStatuses/add_AltDialDispo', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addautoaltdialdispo').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_alt_dial_dispo').modal('hide')
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleMasterDialDefaults = (checked) => {
    let useMasterDialDefaults = checked //!this.state.useMasterDialDefaults;
    console.log(useMasterDialDefaults)
    this.setState({ useMasterDialDefaults })
    if (useMasterDialDefaults) {
      /* this.users_campaign_values = JSON.stringify(
        $(".CampaignEditform").serializeArray()
      );
      console.log(this.users_campaign_values);*/
      //fetch defaults from master and set here ;)
      this.props.blockUI(true)
      fetch(this.props.baseUrl + '/index.php/Campaigns/Ajax', {
        credentials: 'include',
        method: 'post',
        body: new URLSearchParams({ action: 'get_master_dial_settings' }),
      })
        .then((blob) => blob.json())
        .then((data) => {
          console.log(data)
          for (var x in data) {
            window.master_dial_defaults[x] = data[x]
          }
          /*//update all controls values
          this.campaign_caller_id.current.value = data.campaign_cid;
          this.dial_timeout.current.value = data.dial_timeout;
          //this.campaign_caller_id.current. = "readonly";
          ////////////////////////////////////*/
          window.use_master_dial_settings(!useMasterDialDefaults)
          this.setState({
            campaign_cid: data.campaign_cid,
            campaign_cid_type: data.campaign_cid_type,
            sms_cid: data.sms_cid,
          })
          this.props.blockUI(false)
        })
        .catch((e) => {
          this.props.blockUI(false)
        })
      /////////////////////////////////////////////////////////
    } else {
      window.use_master_dial_settings(!useMasterDialDefaults)
      this.setState({
        campaign_cid_type: window.users_settings[0].val,
        campaign_cid: window.users_settings[1].val,
        sms_cid: window.users_settings[2].val,
      })
    }
  }
  handleAddHotKey = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addhotkey').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_alt_dial_dispo').modal('hide')
        this.props.blockUI(false)
        this.dt_hotkeys.api().ajax.reload()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleaddleadrecycle = (e) => {
    e.preventDefault()
    this.props.blockUI(true)
    fetch(this.props.baseUrl + '/index.php/Campaigns/post_process', {
      credentials: 'include',
      method: 'post',
      body: new URLSearchParams($('#form_addleadrecycle').serialize()),
    })
      .then((blob) => {
        return blob.json()
      })
      .then((data) => {
        console.log(data)
        if (data.status) {
          window.displayAlert('#alert_container', data.msg, 'success')
        } else {
          window.displayAlert('#alert_container', data.msg, 'danger')
        }
        window.$('#modal_add_alt_dial_dispo').modal('hide')
        this.props.blockUI(false)
        this.dt_leadrecycle.api().ajax.reload()
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  /*************************Adding dynamic cid code */
  /*handleadddynamiccid = (e) => {
    e.preventDefault();
    this.props.blockUI(true);
    fetch(this.props.baseUrl + "/index.php/Campaigns/post_process", {
      credentials: "include",
      method: "post",
      body: new URLSearchParams($("#form_add_dynamic_cid").serialize()),
    })
      .then((blob) => {
        return blob.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status) {
          window.displayAlert("#alert_container", data.msg, "success");
        } else {
          window.displayAlert("#alert_container", data.msg, "danger");
        }
        window.$("#modal_add_dynamic_cid").modal("hide");
        this.props.blockUI(false);
        this.dt_dynamic_cids.api().ajax.reload();
      })
      .catch((e) => {
        this.props.blockUI(false);
      });
  };*/
  /***************************************************** */
  handleRemoveStandardDispo = (e, status) => {
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl +
        '/index.php/DispStatuses/remove_cdispo/' +
        this.state.campaign_id +
        '/' +
        status,
      { method: 'post', credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handlestddialdisporemove = (e, dispo) => {
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl +
        '/index.php/DispStatuses/remove_Dial_Dispo/' +
        dispo +
        '/' +
        this.state.campaign_id,
      { method: 'post', credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }
  handleAutoAltRemoval = (e, aads) => {
    this.props.blockUI(true)
    fetch(
      this.props.baseUrl +
        '/index.php/DispStatuses/remove_Auto_Alt_Dial_Dispo/' +
        aads +
        '/' +
        this.state.campaign_id,
      { method: 'post', credentials: 'include' }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status) {
          window.displayAlert('#alert_container', resp.msg, 'success')
        }
        this.props.blockUI(false)
        this.initCampaign() //reload campaign stuff
      })
      .catch((e) => {
        this.props.blockUI(false)
      })
  }

  show_add_preset_modal = () => {
    window.show_add_preset_model()
  }
  handle2ndSaveChangesBtn = (e) => {
    $('#saveChanges1').click()
  }

  /****Lists management */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { campaign_lists } = this.state
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(campaign_lists),
        oldIndex,
        newIndex
      ).filter((el) => !!el)
      console.log('Sorted items: ', newData)
      this.setState({ campaign_lists: newData }, () => {
        let list_ids = []
        for (var i = 0; i < newData.length; i++) {
          list_ids.push(newData[i].list_id)
        }
        const body = {
          action: 'arrange_lists',
          list_ids: list_ids,
        }
        window.App.blockUI()
        //update lists order in this campaign
        const requestOptions = {
          method: 'POST',
          credentials: 'include',
          body: new URLSearchParams($.param(body)),
        }
        fetch(
          this.props.baseUrl + '/index.php/Campaigns/post_process',
          requestOptions
        )
          .then((ans) => ans.json())
          .then((data) => {
            if (data.status) {
              window.displayAlert('#alert_container', data.msg, 'success')
            } else {
              window.displayAlert('#alert_container', data.msg, 'danger')
            }
            window.App.unblockUI()
          })
          .catch((ans) => {
            window.App.unblockUI()
          })
      })
    }
  }

  DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  )

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { campaign_lists } = this.state
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = campaign_lists.findIndex(
      (x) => x.index === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }
  ////////////
  toggleListActive = (event, list_id) => {
    let body = {
      action: 'toggle_list_status',
      list_id: list_id,
      status: event.target.checked ? 'Y' : 'N',
    }
    window.App.blockUI()
    fetch(this.props.baseUrl + '/index.php/Lists/Ajax', {
      method: 'POST',
      credentials: 'include',
      body: new URLSearchParams($.param(body)),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.warn(resp)
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /*************************************** */
  spam_caller_id_check = (e, cid) => {
    window.App.blockUI()
    //alert($(this).attr("data-cid"));
    if (cid === undefined) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    if (cid.length < 10) {
      window.bootbox.alert("It isn't a valid CID")
      window.App.unblockUI()
      return
    }
    const Aurl = this.props.baseUrl
    fetch(Aurl + '/index.php/Ajax/scrap_for_spam_cid/' + cid, {
      credentials: 'include',
      method: 'get',
    })
      .then((data) => data.json())
      .then((data) => {
        window.$('#spam_check_cid').html(cid)
        window.$('#mdl_spmchk_cid').html(cid)
        window
          .$('#mdl_spmchk_score')
          .html(data.spam_score + '/' + data.spam_total)
        if (data.spam_score <= 2) {
          window.$('#mdl_spmchk_score').css('color', 'green')
        } else {
          window.$('#mdl_spmchk_score').css('color', 'red')
        }
        var spam_markers = 'None'
        for (var i = 0; i < data.spam_markers.length; i++) {
          if (i == 0)
            spam_markers =
              '<a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
          else
            spam_markers +=
              '<br /><a target="_blank" href="' +
              data.spam_markers[i] +
              '">' +
              data.spam_markers[i] +
              '</a>'
        }
        window.$('#mdl_spmchk_by').html(spam_markers)
        var spam_sources = ''
        for (var i = 0; i < data.spam_sources.length; i++) {
          if (i == 0)
            spam_sources =
              '<small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
          else
            spam_sources +=
              '<br /><small><a target="_blank" href="' +
              data.spam_sources[i] +
              '">' +
              data.spam_sources[i] +
              '</a></small>'
        }
        window.$('#mdl_spmchk_at').html(spam_sources)
        window.$('#mdl_spam_check_cid').modal('show')
        window.App.unblockUI()
      })
      .catch((e) => {
        window.App.unblockUI()
      })
  }
  /*doDynamicCIDWidget = () => {
    if (this.state.campaign_cid_type !== "dynamic") {
      return;
    }
    if (this.dt_dynamic_cids == null) {
      this.dt_dynamic_cids = window.dttable(
        "dt_dynamic_cids",
        this.props.baseUrl +
          "/index.php/Campaigns/Ajax?action=list_dynamic_cids&campaign_id=" +
          this.state.campaign_id,
        ""
      );
      
      var Aurl = this.props.baseUrl;
      var dtable_dynamic_cids = this.dt_dynamic_cids;

      var blockUI = this.props.blockUI;
      $("#dt_dynamic_cids").on("click", ".btn-danger", function () {
        var childKey = $(this).attr("delete_id");
        blockUI(true);
        fetch(Aurl + "/index.php/Campaigns/DynamicCID/delete/" + childKey, {
          method: "GET",
          credentials: "include",
        })
          .then((blob) => {
            return blob.json();
          })
          .then((data) => {
            console.log(data);
            if (data.status) {
              window.displayAlert("#alert_container", data.msg, "success");
            } else {
              window.displayAlert("#alert_container", data.msg, "danger");
            }
            dtable_dynamic_cids.api().ajax.reload();
            blockUI(false);
          })
          .catch((e) => {
            blockUI(false);
          });
      });
      //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//
    } else {
      this.dt_dynamic_cids.api().ajax.reload();
    }
  };
  */
  getmomentarr = () => {
    try {
      let vv = this.state.local_call_times
      if (vv == null || vv == undefined) {
        return [null, null]
      }
      if (vv.indexOf('-') <= 0) {
        return [null, null]
      }
      let a = vv.split('-')
      let ar = [moment(a[0], 'hh:mm:ss a'), moment(a[1], 'hh:mm:ss a')]
      console.log(ar[0])
      console.log(ar[1])
      return ar
    } catch (e) {
      return [null, null]
    }
  }
  render() {
    return (
      <React.Fragment>
        {/*<!-- BEGIN CONTAINER -->*/}
        <div className="page-container">
          {/*<!-- BEGIN CONTENT -->*/}
          <div className="page-content-wrapper">
            {/*<!-- BEGIN CONTENT BODY -->*/}
            {/*<!-- BEGIN PAGE HEAD-->*/}
            <div className="page-head">
              <div className="container">
                {/*<!-- BEGIN PAGE TITLE -->*/}
                <div className="page-title">
                  <h1>
                    Campaign
                    <small>{/**campaign_id */}</small>
                  </h1>
                </div>
                {/*<!-- END PAGE TITLE -->*/}
                <div className="page-toolbar  tabbable-line">
                  <ul className="nav nav-tabs pull-right">
                    <li className="active">
                      <a href="#tab_settings" data-toggle="tab">
                        {' '}
                        Settings{' '}
                      </a>
                    </li>
                    <li>
                      <a href="#tab_lists" data-toggle="tab">
                        {' '}
                        Lists{' '}
                      </a>
                    </li>
                    <li>
                      <a href="#tab_hotkeys" data-toggle="tab">
                        {' '}
                        Hotkeys{' '}
                      </a>
                    </li>
                    <li>
                      <a href="#tab_leadRecycle" data-toggle="tab">
                        {' '}
                        Lead Recycle{' '}
                      </a>
                    </li>
                    {/*<!--<li>
							<a href="#tab_accid" data-toggle="tab"> AC-CID </a>
						</li>-->*/}
                  </ul>
                </div>
              </div>
            </div>
            {/*<!-- END PAGE HEAD-->*/}
            {/*<!-- BEGIN PAGE CONTENT BODY -->*/}
            <div className="page-content">
              <div className="container">
                {/*<!-- BEGIN PAGE CONTENT INNER -->*/}
                <div className="page-content-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <span id="alert_container"></span>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tab_settings">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          {' '}
                                          Settings
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                    </div>
                                    <div className="portlet-body">
                                      <form
                                        className="CampaignEditForm"
                                        onSubmit={this.handlesubmitEditCampaign}
                                        role="form"
                                        action=""
                                        method="post"
                                      >
                                        <input
                                          type="hidden"
                                          id="action"
                                          name="action"
                                          value="update_campaign_settings"
                                        />
                                        <input
                                          type="hidden"
                                          id="campaign_id"
                                          name="campaign_id"
                                          value={this.state.campaign_id}
                                        />
                                        <table className="table table-striped table-bordered table-advance table-hover">
                                          <tbody>
                                            <tr>
                                              <td className="highlight">
                                                Campaign ID
                                              </td>
                                              <td>
                                                {' '}
                                                <input
                                                  id="campaign_id"
                                                  type="text"
                                                  className="form-control"
                                                  name="campaign_id"
                                                  defaultValue={
                                                    this.state.campaign_id
                                                  }
                                                  readOnly
                                                  disabled
                                                />{' '}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Campaign Name
                                              </td>
                                              <td>
                                                {' '}
                                                <input
                                                  id="campaign_name"
                                                  type="text"
                                                  className="form-control"
                                                  name="campaign_name"
                                                  value={
                                                    this.state.campaign_name
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      campaign_name:
                                                        e.target.value,
                                                    })
                                                  }}
                                                />{' '}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Active
                                              </td>
                                              <td>
                                                {' '}
                                                <select
                                                  id="campaign_active"
                                                  className="bs-select form-control"
                                                  name="campaign_active"
                                                  value={this.state.active}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      active: e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option value="Y">Y</option>
                                                  <option value="N">N</option>
                                                </select>{' '}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="2">
                                                <input
                                                  type="hidden"
                                                  name="use_master_dial_defaults"
                                                  value={
                                                    this.state
                                                      .useMasterDialDefaults
                                                      ? 1
                                                      : 0
                                                  }
                                                />
                                                <Checkbox
                                                  onChange={(e) => {
                                                    this.handleMasterDialDefaults(
                                                      e.target.checked
                                                    )
                                                  }}
                                                  checked={
                                                    this.state
                                                      .useMasterDialDefaults
                                                  }
                                                >
                                                  Use Master Dial Defaults
                                                </Checkbox>
                                                {/*<div className="checkbox-list">
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      id="use_master_dial"
                                                      name="use_master_dial_defaults"
                                                      defaultValue="1"
                                                      checked={
                                                        this.state
                                                          .useMasterDialDefaults
                                                      }
                                                      onClick={
                                                        this
                                                          .handleMasterDialDefaults
                                                      }
                                                    />
                                                    Use Master Dial Defaults
                                                  </label>
                                                </div>*/}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Campaign CID Type
                                              </td>
                                              <td>
                                                <select
                                                  className="form-control modifiable"
                                                  id="campaign_cid_type"
                                                  name="campaign_cid_type"
                                                  value={
                                                    this.state.campaign_cid_type
                                                  }
                                                  onChange={(e) => {
                                                    this.setState(
                                                      {
                                                        campaign_cid_type:
                                                          e.target.value,
                                                      },
                                                      () => {
                                                        // if (
                                                        //   this.state
                                                        //     .campaign_cid_type ===
                                                        //   'dynamic'
                                                        // ) {
                                                        //   this.doDynamicCIDWidget()
                                                        // } else if (
                                                        //   this.state
                                                        //     .campaign_cid_type ===
                                                        //   'accid'
                                                        // ) {
                                                        // }
                                                      }
                                                    )
                                                  }}
                                                >
                                                  {/*<option value="standard">
                                                    Standard
                                                </option>*/}
                                                  <option value="dynamic">
                                                    Rotation
                                                  </option>
                                                  <option value="accid">
                                                    Area Code Switching
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            {this.state.campaign_cid_type ===
                                              'accid' && (
                                              <tr>
                                                <td className="highlight">
                                                  Default Campaign Caller ID
                                                  &nbsp;
                                                  {/*<a
                                                  title="Check Caller ID for potential SPAM flag."
                                                  class="fa fa-exclamation-triangle"
                                                  href="javascript:;"
                                                  onClick={(e) =>
                                                    this.spam_caller_id_check(
                                                      e,
                                                      this.state.campaign_cid
                                                    )
                                                  }
                                                ></a>*/}
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control modifiable"
                                                    id="campaign_cid"
                                                    name="campaign_cid"
                                                    value={
                                                      this.state.campaign_cid
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        campaign_cid:
                                                          e.target.value,
                                                      })
                                                    }}
                                                    readOnly={
                                                      this.state
                                                        .campaign_cid_type ==
                                                      'dynamic'
                                                    }
                                                    required={
                                                      this.state
                                                        .campaign_cid_type ===
                                                      'accid'
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            )}
                                            <input
                                              type="hidden"
                                              name="campaign_cid_type"
                                              id="campaign_cid_type"
                                              value={
                                                this.state.campaign_cid_type
                                              }
                                            />
                                            <input
                                              type="hidden"
                                              name="campaign_cid"
                                              id="campaign_cid"
                                              value={this.state.campaign_cid}
                                            />
                                            <tr>
                                              <td className="highlight">
                                                SMS Caller ID &nbsp;
                                                {/*<a
                                                  title="Check Caller ID for potential SPAM flag."
                                                  class="fa fa-exclamation-triangle"
                                                  href="javascript:;"
                                                  onClick={(e) =>
                                                    this.spam_caller_id_check(
                                                      e,
                                                      this.state.sms_cid
                                                    )
                                                  }
                                                ></a>*/}
                                                <br />
                                                <small>
                                                  SMS Caller ID (not to be used
                                                  as rotating caller IDs) You
                                                  must register for 10DLC to
                                                  obtain an SMS enabled caller
                                                  ID number which may take 7+
                                                  days. Please contact support
                                                  for help.
                                                </small>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control modifiable"
                                                  id="sms_cid"
                                                  name="sms_cid"
                                                  value={this.state.sms_cid}
                                                  onChange={(e) => {
                                                    this.setState({
                                                      sms_cid: e.target.value,
                                                    })
                                                  }}
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td className="highlight">
                                                {/*<!--Preview -->*/}Mode
                                              </td>
                                              <td>
                                                <select
                                                  className="bs-select form-control modifiable"
                                                  id="manual_preview_dial"
                                                  name="campaign_preview_mode"
                                                  value={
                                                    this.state
                                                      .manual_preview_dial
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      manual_preview_dial:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option value="PREVIEW_ONLY">
                                                    Preview Only
                                                  </option>
                                                  <option value="PREVIEW_AND_SKIP">
                                                    Preview And Skip
                                                  </option>
                                                  <option value="DISABLED">
                                                    Power Dialing
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  paddingTop: '20px',
                                                }}
                                              >
                                                Call Time&nbsp;
                                                <i
                                                  style={{ fontSize: '15px' }}
                                                  title="Call Time allows you to set an allowable time for agents to call the lists in this campaign based on the system Time Zone setting under Configurations.  Note: Some people move to a different time zone, but keep their phone number from their previous time zone; therefore, you could potentially call someone at a time of day that is not allowed."
                                                  class="material-icons"
                                                >
                                                  question_mark
                                                </i>
                                              </td>
                                              <td>
                                                <input
                                                  type="hidden"
                                                  name="local_call_time"
                                                  value={
                                                    this.state.local_call_times
                                                  }
                                                />
                                                <RangePicker
                                                  onOpenChange={(a) => {
                                                    if (a) {
                                                      if (
                                                        !this.state
                                                          .once_displayed_call_time_modal_on_call_time_focus
                                                      ) {
                                                        if (
                                                          !window
                                                            .$(
                                                              '#static_call_time_modal'
                                                            )
                                                            .is(':visible')
                                                        ) {
                                                          window
                                                            .$(
                                                              '#static_call_time_modal'
                                                            )
                                                            .modal('show')
                                                        }
                                                        this.setState({
                                                          once_displayed_call_time_modal_on_call_time_focus: true,
                                                        })
                                                      }
                                                    }
                                                  }}
                                                  use12Hours={true}
                                                  onChange={(val, time) => {
                                                    this.setState({
                                                      local_call_times:
                                                        time.join('-'),
                                                    })
                                                  }}
                                                  className="form-control"
                                                  format={'hh:mm:ss a'}
                                                  value={this.getmomentarr()}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Script
                                              </td>
                                              <td>
                                                <select
                                                  name="campaign_script"
                                                  id="campaign_script"
                                                  className="bs-select form-control modifiable"
                                                  value={
                                                    this.state.campaign_script
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      campaign_script:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option></option>
                                                  {this.state.scripts_list.map(
                                                    (script) => (
                                                      <option
                                                        value={script.key}
                                                      >
                                                        {script.value +
                                                          ' (' +
                                                          script.key +
                                                          ')'}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                {/*<ScriptsDropDown
                                              baseUrl={this.props.baseUrl}
                                              script={this.state.script}
                                              className="bs-select form-control modifiable"
                                              id="campaign_script"
                                              name="campaign_script"
                                            ></ScriptsDropDown>*/}
                                                {/*<!--<input type="text" className="form-control" id="campaign_script" name="campaign_script" value="<?=$campaign['campaign_script']?>" /> -->*/}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Campaign Recording
                                              </td>
                                              <td>
                                                <select
                                                  className="bs-select form-control modifiable"
                                                  id="campaign_recording"
                                                  name="campaign_recording"
                                                  value={
                                                    this.state
                                                      .campaign_recording
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      campaign_recording:
                                                        e.target
                                                          .campaign_recording,
                                                    })
                                                  }}
                                                  disabled={
                                                    this.state
                                                      .enable_recording == 'Yes'
                                                      ? ''
                                                      : 'disabled'
                                                  }
                                                >
                                                  <option value="NONE">
                                                    None
                                                  </option>
                                                  <option value="AGENTRECORD">
                                                    Agent Record
                                                  </option>
                                                  <option value="RECORDALL">
                                                    Record All
                                                  </option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Dial Timeout
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control modifiable"
                                                  id="dial_timeout"
                                                  name="dial_timeout"
                                                  value={
                                                    this.state.dial_timeout
                                                  }
                                                  onChange={(e) => {
                                                    const parsedN =
                                                      Number.parseInt(
                                                        e.target.value
                                                      )
                                                    if (Number.isNaN(parsedN)) {
                                                      window.displayAlert(
                                                        '#alert_container',
                                                        'Dial Time out must be a number',
                                                        'danger'
                                                      )
                                                    } else if (
                                                      Number.parseInt(parsedN) <
                                                      30
                                                    ) {
                                                      window.displayAlert(
                                                        '#alert_container',
                                                        'Dial Time out cannot be less than 30',
                                                        'danger'
                                                      )
                                                    } else {
                                                      window
                                                        .$('#alert_container')
                                                        .html('')
                                                    }
                                                    this.setState({
                                                      dial_timeout:
                                                        e.target.value,
                                                    })
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="highlight">
                                                Auto Alt Dial
                                              </td>
                                              <td>
                                                <select
                                                  className="bs-select form-control modifiable"
                                                  id="auto_alt_dial"
                                                  name="auto_alt_dial"
                                                  value={
                                                    this.state.auto_alt_dial ||
                                                    ''
                                                  }
                                                  onChange={(e) => {
                                                    this.setState({
                                                      auto_alt_dial:
                                                        e.target.value,
                                                    })
                                                  }}
                                                >
                                                  <option>YES</option>
                                                  <option>NO</option>
                                                </select>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colspan="2">
                                                <Collapse>
                                                  <Collapse.Panel>
                                                    <table className="table table-striped table-bordered table-advance table-hover">
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            Override Agent CID
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="agent_cid_override"
                                                              name="agent_cid_override"
                                                              value={
                                                                this.state
                                                                  .agent_cid_override
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  agent_cid_override:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="Y">
                                                                Yes
                                                              </option>
                                                              <option value="N">
                                                                No
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/*Adding dialing list order*/}
                                                        <tr>
                                                          <td>Dial Order</td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="campaign_dial_order"
                                                              name="campaign_dial_order"
                                                              value={
                                                                this.state
                                                                  .campaign_dial_order
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  campaign_dial_order:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="standard">
                                                                Newest Uncalled
                                                                Leads"
                                                                (standard)
                                                              </option>
                                                              <option value="list_priority">
                                                                List Priority
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/****** */}
                                                        {/********Adding option to select which cid to use when calling 3rd party in warm and 3way conference */}
                                                        <tr>
                                                          <td>
                                                            Conference CID
                                                          </td>
                                                          <td>
                                                            <select
                                                              className="bs-select form-control modifiable"
                                                              id="three_way_call_cid"
                                                              name="three_way_call_cid"
                                                              value={
                                                                this.state
                                                                  .three_way_call_cid
                                                              }
                                                              onChange={(e) => {
                                                                this.setState({
                                                                  three_way_call_cid:
                                                                    e.target
                                                                      .value,
                                                                })
                                                              }}
                                                            >
                                                              <option value="CAMPAIGN">
                                                                Dolphin
                                                              </option>
                                                              <option value="CUSTOMER">
                                                                Customer
                                                              </option>
                                                            </select>
                                                          </td>
                                                        </tr>
                                                        {/*********** */}
                                                      </tbody>
                                                    </table>
                                                  </Collapse.Panel>
                                                </Collapse>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                className="text-center"
                                                colspan="2"
                                              >
                                                <button
                                                  type="submit"
                                                  className="btn green-jungle"
                                                  id="saveChanges1"
                                                >
                                                  Save Changes
                                                </button>
                                                {/*<!--<button type="button" className="btn default">Cancel</button>-->*/}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          {' '}
                                          Dispositions
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                      {/*<!--<div className="actions">
														<a href="javascript:;" className="btn btn-circle btn-default btn-sm">
															<i className="fa fa-plus"></i> Add </a>
														<a className="btn btn-circle btn-icon-only btn-default fullscreen" href="javascript:;"></a>
													</div>-->*/}
                                    </div>
                                    <div className="portlet-body">
                                      <table className="table table-striped table-bordered table-advance table-hover">
                                        <tbody>
                                          {/*<!-- Adding a new section Default dispos which will visible only for non master campaigns and will contain master dial dispos here only for non master campaigns-->*/}
                                          <tr>
                                            <td className="highlight">
                                              Standard Dispos
                                            </td>
                                            <td>
                                              <div className="checkbox-list">
                                                {this.state.system_dispos.map(
                                                  (sd) => (
                                                    <label
                                                      key={
                                                        sd.status +
                                                        sd.status_name
                                                      }
                                                    >
                                                      {sd.status +
                                                        ' - ' +
                                                        sd.status_name}
                                                      {sd.sale == 'Y' ? (
                                                        <b>(s)</b>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {sd.DNC == 'Y' ? (
                                                        <b>(dnc)</b>
                                                      ) : (
                                                        ''
                                                      )}
                                                      {sd.scheduled_callback ==
                                                      'Y' ? (
                                                        <b>(cb)</b>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </label>
                                                  )
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                          {/*<!-------------------------------------->*/}
                                          <tr>
                                            <td className="highlight">
                                              Campaign Specific Dispos
                                              <a
                                                data-toggle="modal"
                                                href="#modal_add_camp_dispo"
                                                id="add_camp_dispo"
                                              >
                                                <i className="fa fa-plus"></i>
                                              </a>
                                            </td>
                                            <td>
                                              <div className="accordion">
                                                <div className="checkbox-list">
                                                  {this.state.campaign_dispos.map(
                                                    (cd) => (
                                                      <label>
                                                        {cd.status +
                                                          ' - ' +
                                                          cd.status_name}
                                                        {cd.sale == 'Y' ? (
                                                          <b>(s)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {cd.DNC == 'Y' ? (
                                                          <b>(dnc)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        {cd.scheduled_callback ==
                                                        'Y' ? (
                                                          <b>(cb)</b>
                                                        ) : (
                                                          ''
                                                        )}
                                                        <Popconfirm
                                                          title=" Are you sure you want to delete this disposition?"
                                                          onConfirm={(e) => {
                                                            this.handleRemoveStandardDispo(
                                                              e,
                                                              cd.status
                                                            )
                                                          }}
                                                          okText="Yes"
                                                          cancelText="No"
                                                          className="pull-right"
                                                        >
                                                          <a
                                                            className="pull-right"
                                                            id="remove_dial_dispo_id"
                                                          >
                                                            <i className="fa fa-minus"></i>
                                                          </a>
                                                        </Popconfirm>
                                                      </label>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr className="hidden">
                                            <td className="highlight">
                                              Dial Dispos
                                              <a
                                                data-toggle="modal"
                                                href="#modal_add_sys_dispo"
                                                id="add_dial_dispo"
                                              >
                                                <i className="fa fa-plus"></i>
                                              </a>
                                            </td>
                                            <td
                                              className="hidden-xs accordion"
                                              id="acc_dial_dispo"
                                            >
                                              <div className="panel panel-default">
                                                <div className="panel-heading">
                                                  <h4 className="panel-title">
                                                    <a
                                                      className="accordion-toggle accordion-toggle-styled collapsed"
                                                      data-toggle="collapse"
                                                      aria-expanded="false"
                                                      data-parent="#acc_dial_dispo"
                                                      href="#acc_dial_dispo_cc"
                                                    ></a>
                                                  </h4>
                                                </div>
                                                <div
                                                  id="acc_dial_dispo_cc"
                                                  className="panel-collapse collapse"
                                                  aria-expanded="false"
                                                >
                                                  <div className="panel-body">
                                                    <div className="checkbox-list">
                                                      {this.state.dial_dispo.map(
                                                        (dd) => (
                                                          <label key={dd}>
                                                            {dd}
                                                            {!this.state.standard_dispo.includes(
                                                              dd
                                                            ) ? (
                                                              <a
                                                                className="pull-right"
                                                                data-toggle="confirmation"
                                                                data-popout="true"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  this.handlestddialdisporemove(
                                                                    e,
                                                                    dd
                                                                  )
                                                                }}
                                                                id="remove_dial_dispo_id"
                                                              >
                                                                <i className="fa fa-minus"></i>
                                                              </a>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </label>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr className="hidden">
                                            <td className="highlight">
                                              Alt Dial Statuses{' '}
                                              <a
                                                data-toggle="modal"
                                                href="#modal_add_alt_dial_dispo"
                                                id="add_alt_dial_dispo"
                                              >
                                                <i className="fa fa-plus"></i>
                                              </a>
                                            </td>
                                            <td
                                              className="hidden-xs accordion"
                                              id="acc_alt_dial_dispo"
                                            >
                                              <div className="panel panel-default">
                                                <div className="panel-heading">
                                                  <h4 className="panel-title">
                                                    <a
                                                      className="accordion-toggle accordion-toggle-styled collapsed"
                                                      data-toggle="collapse"
                                                      aria-expanded="false"
                                                      data-parent="#acc_alt_dial_dispo"
                                                      href="#acc_alt_dial_dispo_cc"
                                                    ></a>
                                                  </h4>
                                                </div>
                                                <div
                                                  id="acc_alt_dial_dispo_cc"
                                                  className="panel-collapse collapse"
                                                  aria-expanded="false"
                                                >
                                                  <div className="panel-body">
                                                    <div className="checkbox-list">
                                                      {this.state.auto_alt_dial_statuses.map(
                                                        (aads) => (
                                                          <label>
                                                            {aads}
                                                            <a
                                                              onClick={(e) => {
                                                                this.handleAutoAltRemoval(
                                                                  e,
                                                                  aads
                                                                )
                                                              }}
                                                              className="pull-right"
                                                              data-toggle="confirmation"
                                                              data-popout="true"
                                                              data-original-title=""
                                                              title=""
                                                              id="remove_dial_dispo_id"
                                                            >
                                                              <i className="fa fa-minus"></i>
                                                            </a>
                                                          </label>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="text-center"
                                              colspan="2"
                                            >
                                              <button
                                                type="submit"
                                                className="btn green-jungle"
                                                onClick={
                                                  this.handle2ndSaveChangesBtn
                                                }
                                              >
                                                Save Changes
                                              </button>
                                              {/*<!--<button type="button" className="btn default">Cancel</button>-->*/}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      {/*</form>*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                {this.state.campaign_cid_type == 'dynamic' && (
                                  <DynamicCIDCampaignWidget
                                    baseUrl={this.props.baseUrl}
                                    campaign_id={this.state.campaign_id}
                                    blockUI={this.props.blockUI}
                                    follow_master={
                                      this.state.useMasterDialDefaults
                                    }
                                  />
                                )}
                                {this.state.campaign_cid_type === 'accid' && (
                                  <AreaCIDCampaignWidget
                                    baseUrl={this.props.baseUrl}
                                    campaign_id={this.state.campaign_id}
                                    blockUI={this.props.blockUI}
                                  />
                                )}
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="portlet portlet-sortable light bordered">
                                    <div className="portlet-title">
                                      <div className="caption font-green-sharp">
                                        <i className="icon-speech font-green-sharp"></i>
                                        <span className="caption-subject bold uppercase">
                                          {' '}
                                          Transfer Presets
                                        </span>
                                        <span className="caption-helper"></span>
                                      </div>
                                      <div className="actions">
                                        <a
                                          onClick={this.show_add_preset_modal}
                                          id="modal_AddPreset"
                                          className="btn btn-circle btn-default btn-sm"
                                        >
                                          <i className="fa fa-plus"></i> Add{' '}
                                        </a>
                                      </div>
                                    </div>
                                    <div className="portlet-body">
                                      <table
                                        className="table table-striped table-bordered table-advance table-hover"
                                        id="dt_presets"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Preset Label</th>
                                            <th>Number</th>
                                            <th>DTMF</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/*<!-- we will retreive presets from campaign and whos them here-->*/}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane <?=$campaign_id=='Master'?'hidden':''?>"
                          id="tab_lists"
                        >
                          <div className="portlet portlet-sortable light bordered">
                            {/*<!--<div className="portlet-title">
											<div className="caption font-green-sharp">
												<i className="icon-speech font-green-sharp"></i>
												<span className="caption-subject bold uppercase"> Settings</span>
												<span className="caption-helper"></span>
											</div>											
										</div>-->*/}
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <Table
                                    pagination={false}
                                    dataSource={this.state.campaign_lists}
                                    columns={this.columns}
                                    rowKey="index"
                                    components={{
                                      body: {
                                        wrapper: this.DraggableContainer,
                                        row: this.DraggableBodyRow,
                                      },
                                    }}
                                  />
                                  {/*<table className="table table-striped table-bordered table-advance table-hover">
                                    <thead>
                                      <tr>
                                        <th>List ID</th>
                                        <th>List Name</th>
                                        <th>Leads (Dialables)</th>                                        
                                        <th>Active</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.campaign_lists.map(
                                        (list, i) => (
                                          <tr>
                                            <td>{list.list_id}</td>
                                            <td>{list.list_name}</td>
                                            <td>
                                              {list.leads +
                                                " (" +
                                                list.dialable_leads +
                                                ")"}
                                            </td>
                                            <td>
                                              <Checkbox
                                                onChange={(ev) => {
                                                  this.toggleListActive(
                                                    ev,
                                                    list.list_id
                                                  );
                                                  let tstate = this.state;
                                                  if (ev.target.checked)
                                                    tstate.campaign_lists[
                                                      i
                                                    ].active = "Y";
                                                  else
                                                    tstate.campaign_lists[
                                                      i
                                                    ].active = "N";
                                                  this.setState(tstate);
                                                }}
                                                checked={list.active == "Y"}
                                              ></Checkbox>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tab_accid"></div>
                        <div className="tab-pane" id="tab_hotkeys">
                          <div className="portlet portlet-sortable light bordered">
                            <div className="portlet-title">
                              <div className="caption font-green-sharp">
                                <i className="icon-speech font-green-sharp"></i>
                                <span className="caption-subject bold uppercase">
                                  ADD Hotkey
                                </span>
                                <span className="caption-helper"></span>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  {/*<?php echo form_open(base_url('index.php/Campaigns/Modify/'.$campaign_id), array('method'=>'POST','class'=>'form-horizontal'))?>*/}
                                  <form
                                    id="form_addhotkey"
                                    onSubmit={this.handleAddHotKey}
                                  >
                                    <input
                                      type="hidden"
                                      name="action"
                                      value="add_hotkey"
                                    />
                                    <input
                                      type="hidden"
                                      name="campaign_id"
                                      value={this.state.campaign_id}
                                    />
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                      <tbody>
                                        <tr>
                                          <td className="highlight">HotKey</td>
                                          <td>
                                            <select
                                              className="bs-select form-control"
                                              name="hotkey"
                                              id="hotkey"
                                            >
                                              {this.hotkey_options.map(
                                                (hto) => (
                                                  <option key={hto}>
                                                    {hto}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </td>
                                          <td className="highlight">Status</td>
                                          <td>
                                            <select
                                              className="bs-select form-control"
                                              name="hotkey_status"
                                              id="hotkey_status"
                                            >
                                              {this.state.all_statuses.map(
                                                (st) => (
                                                  <option>
                                                    {st.status +
                                                      ' - ' +
                                                      st.status_name}
                                                  </option>
                                                )
                                              )}
                                              {/*<?=Dolphin_AllStatuses($campaign_id, true)?>*/}
                                            </select>
                                          </td>
                                          <td>
                                            <button className="btn green-jungle">
                                              Add
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </form>
                                  {/*<?php echo form_close()?>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<!---->*/}
                          <div className="portlet portlet-sortable light bordered">
                            <div className="portlet-title">
                              <div className="caption font-green-sharp">
                                <i className="icon-speech font-green-sharp"></i>
                                <span className="caption-subject bold uppercase">
                                  {' '}
                                  Hotkeys
                                </span>
                                <span className="caption-helper"></span>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <table
                                    className="table table-striped table-bordered table-advance table-hover"
                                    id="dt_hotkeys"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Hot Key</th>
                                        <th>Status</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody></tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="tab_leadRecycle">
                          {/*<!---Leada Recycle content-->*/}
                          <div className="portlet portlet-sortable light bordered">
                            <div className="portlet-title">
                              <div className="caption font-green-sharp">
                                <i className="icon-speech font-green-sharp"></i>
                                <span className="caption-subject bold uppercase">
                                  ADD Lead Recycle
                                </span>
                                <span className="caption-helper"></span>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  {/*<?php echo form_open(base_url('index.php/Campaigns/Modify/'.$campaign_id), array('method'=>'POST','class'=>'form-horizontal'))?>*/}
                                  <form
                                    id="form_addleadrecycle"
                                    onSubmit={this.handleaddleadrecycle}
                                  >
                                    <input
                                      type="hidden"
                                      name="action"
                                      value="add_leadRecycle"
                                    />
                                    <input
                                      type="hidden"
                                      name="campaign_id"
                                      value={this.state.campaign_id}
                                    />
                                    <table className="table table-striped table-bordered table-advance table-hover">
                                      <tbody>
                                        <tr>
                                          <td className="highlight">Status</td>
                                          <td>
                                            <select
                                              className="bs-select form-control"
                                              name="lr_status"
                                              id="lr_status"
                                            >
                                              {this.state.all_statuses.map(
                                                (st) => (
                                                  <option>
                                                    {st.status +
                                                      ' - ' +
                                                      st.status_name}
                                                  </option>
                                                )
                                              )}
                                              {/*<?=Dolphin_AllStatuses($campaign_id, true)?>*/}
                                            </select>
                                          </td>

                                          <td className="highlight">
                                            Attempt Delay
                                          </td>
                                          <td>
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="attempt_delay"
                                            />
                                          </td>

                                          <td className="highlight">
                                            Attempt Maximum
                                          </td>
                                          <td>
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="attempt_maximum"
                                            />
                                          </td>

                                          <td>
                                            <button className="btn green-jungle">
                                              Add
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </form>

                                  <div
                                    className="alert alert-info"
                                    style={{ padding: '5px' }}
                                  >
                                    <table
                                      className="table table-responsive"
                                      style={{ padding: '0', margin: '0' }}
                                    >
                                      <tbody>
                                        <tr>
                                          <th>
                                            <small> Attempt Delay:</small>
                                          </th>
                                          <td>
                                            {' '}
                                            <small>1 hour=3600 </small>
                                          </td>
                                          <td>
                                            <small> 6 hours=21600 </small>
                                          </td>
                                          <td>
                                            <small> 12 hours=43200 </small>
                                          </td>
                                          <td>
                                            <small> 1 Day=86400 </small>
                                          </td>
                                          <td>
                                            <small>
                                              2 Days=172800 <small></small>
                                            </small>
                                          </td>
                                          <td>
                                            <small> 3 Days=259200 </small>
                                          </td>
                                          <td>
                                            <small> 1 Week=604800</small>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="alert alert-info">
                                    * Lead counts taken from active lists in the
                                    campaign only.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<!---->*/}
                          <div className="portlet portlet-sortable light bordered">
                            <div className="portlet-title">
                              <div className="caption font-green-sharp">
                                <i className="icon-speech font-green-sharp"></i>
                                <span className="caption-subject bold uppercase">
                                  LEAD RECYCLING WITHIN THIS CAMPAIGN{' '}
                                </span>
                                <span className="caption-helper"></span>
                              </div>
                            </div>
                            <div className="portlet-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <table
                                    className="table table-striped table-bordered table-advance table-hover"
                                    id="dt_leadrecycle"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Status</th>
                                        <th>Attempt Delay</th>
                                        <th>Attempt Maximum</th>
                                        <th>Leads at Limit</th>
                                        <th>Active</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody></tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*<!--- Lead Recycle Content End -->*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<!---------------------------------------------MODALS---------------->*/}

        <div
          className="modal fade draggable-modal"
          id="modal_add_sys_dispo"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add new Dialable Disposition</h4>
              </div>
              <form
                className="form-horizontal"
                onSubmit={this.handleAddDialableDispo}
                id="form_adddialdispo"
                method="post"
              >
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="control-label col-md-3">Status</label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="dispo_id"
                        >
                          {this.state.system_dispos.map((sd) => (
                            <option value={sd.status}>{sd.status_name}</option>
                          ))}
                          {this.state.campaign_dispos.map((cd) => (
                            <option value={cd.status}>{cd.status_name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>

        <div
          className="modal fade draggable-modal"
          id="modal_add_camp_dispo"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  Add new Campaign Specific Disposition
                </h4>
              </div>
              <form
                className="form-horizontal"
                id="form_add_campaign_dispo"
                onSubmit={this.handleaddCampaignDispo}
                action="<?=base_url('index.php/DispStatuses/Add_campaignDispo')?>"
                method="post"
              >
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Dispo Code
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          maxLength="5"
                          name="disp_abb"
                          placeholder="Enter text"
                        />
                        <span className="help-block">
                          {' '}
                          This is the abbreviation version of the status used
                          for APIs and reporting. Ex: &quot;NA&quot;{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Dispo Name
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="disp_name"
                          placeholder="Enter text"
                        />
                        <span className="help-block">
                          {' '}
                          This is the name of the disposition the agents will
                          see when making a selection. Ex: &quot;No Answer&quot;{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3">
                        Status Type
                      </label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="disp_type"
                        >
                          <option value="selectable">Normal</option>
                          <option value="sale">Sale</option>
                          <option value="callbk">Call back</option>
                          <option value="DNC">Do Not Call</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------->*/}

        {/*<!-----------alt dial dispo------------------->*/}
        <div
          className="modal fade draggable-modal"
          id="modal_add_alt_dial_dispo"
          tabindex="-1"
          role="basic"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">
                  ADD NEW AUTO ALT NUMBER DIALING STATUS
                </h4>
              </div>
              <form
                className="form-horizontal"
                onSubmit={this.handleaddautoaltdialdispo}
                id="form_addautoaltdialdispo"
                method="post"
              >
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="control-label col-md-3">Status</label>
                      <div className="col-md-4">
                        <select
                          className="sel2_in_modal form-control"
                          name="dispo_id"
                        >
                          {this.state.system_dispos.map((sd) => (
                            <option value={sd.status}>{sd.status_name}</option>
                          ))}
                          {this.state.campaign_dispos.map((cd) => (
                            <option value={cd.status}>{cd.status_name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/*<!-- /.modal-content -->*/}
          </div>
          {/*<!-- /.modal-dialog -->*/}
        </div>
        {/*<!--------------------------------------------------------->*/}
        {/*Transfer preset modal start */}
        <div
          id="modal_trxPreset"
          className="modal fade"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                ></button>
                <h4 className="modal-title">Add New Transfer Preset</h4>
              </div>
              <form
                className="form-horizontal"
                id="form_addtransferpreset"
                onSubmit={this.handleaddtransferpreset}
                action="<?=base_url('index.php/Campaigns/Modify/'.(isset($campaign_id)?$campaign_id:''))?>"
                method="post"
              >
                <input
                  type="hidden"
                  id="mdl_add_preset_act"
                  name="action"
                  value="mdl_add_preset"
                />
                <input
                  type="hidden"
                  name="campaign_id"
                  value={this.state.campaign_id}
                />
                <input
                  type="hidden"
                  id="mdl_preset_id"
                  name="mdl_preset_id"
                  value=""
                />
                <div className="modal-body">
                  <div className="form-body">
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Preset Name
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_name"
                          name="mdl_preset_name"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the label of preset.{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-md-3 control-label">
                        Preset Number
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_num"
                          name="mdl_preset_num"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the phone number associated to preset.{' '}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label col-md-3 ">DTMF</label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          id="mdl_preset_dtmf"
                          name="mdl_preset_dtmf"
                          className="col-md-12 form-control"
                        />
                        <span className="help-block">
                          {' '}
                          This is the DRMF number associated to preset.{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn dark btn-outline"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn green-jungle">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/**Transfer preset modal end */}
        {/***Modal for agreement sining */}
        <div
          id="static_call_time_modal"
          className="modal fade"
          tabIndex="-1"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                        <h4 className="modal-title">
                          Calling Software and Phone Services Agreement
                        </h4>
                      </div> */}
              <div className="modal-body">
                <p>
                  Call Time restricts the time that agents are allowed to dial
                  on this campaign based on the system Time Zone setting under
                  Configurations. Note: Keep in mind, some customers may move to
                  different parts of the country. For example, while a
                  customer's area code may be east coast, they may actually be
                  living on the west coast. Therefore you could potentially call
                  someone at a time of day that's not legally allowed.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn green"
                  onClick={(e) => {
                    if (window.$('#static_call_time_modal').is(':visible')) {
                      window.$('#static_call_time_modal').modal('hide')
                    }
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/******Modal for agreement end */}
      </React.Fragment>
    )
  }
}

export default CampaignEdit
